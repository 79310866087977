import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import React from 'react';
import { Layout } from '@gik/ui/gik/Layout/Layout';
import type { LandingPageHero } from '../types/LandingPageHero';
import { isEmpty } from '@gik/core/utils/validator';
import { Image } from '@gik/ui/Image';
import { Animation } from '@gik/ui/Animation';

export interface IHeroCardProps {
  hero: LandingPageHero;
}

/**
 * https://www.figma.com/design/FT2ninjhNiQOXfnOTaZfqy/%F0%9F%91%A8%F0%9F%8F%BC%E2%80%8D%F0%9F%8E%A4-Jade's-workspace-of-asset-creation?node-id=4696-8681&m=dev
 */
function HeroCardComp({
  hero: { icon, animation, title, content },
  children,
}: React.PropsWithChildren<IHeroCardProps>) {
  const bem = useBemCN('hero-card');
  return (
    <Layout {...bem()} direction={'column-reverse md:row-reverse'}>
      {(!isEmpty(icon) || !isEmpty(animation)) && (
        <Layout
          {...bem('aside')}
          element={'aside'}
          direction={'row'}
          align={'center'}
          perpendicular={'end'}
          flex={'1 1 50%'}
        >
          {icon && <Image src={icon} alt={'Icon'} className={'tw-w-full'} />}
          {animation && <Animation mode={'looped'} data={animation.data} assetsPrepend={animation.assetsPrepend} />}
        </Layout>
      )}
      <Layout
        direction={'column'}
        align={'start md:center'}
        perpendicular={'stretch md:start'}
        flex={'1 1 50%'}
        gap={4}
        className={'md:tw-pb-16'}
      >
        <h1 {...bem('heading', null, headingClassNames)}>{title}</h1>
        <p {...bem('content', null, contentClassNames)}>{content}</p>
        {children}
      </Layout>
    </Layout>
  );
}

export const HeroCard = withComponentErrorBoundary(HeroCardComp);

const headingClassNames = [
  'tw-font-heading',
  'tw-text-neutral-900',
  'tw-m-0',
  'tw-text-4xl',
  'tw-font-semibold',
  'tw-text-center',
  'md:tw-font-bold',
  'md:tw-text-6xl',
  'md:tw-text-left',
];
const contentClassNames = [
  'tw-text-neutral-800',
  'tw-font-body',
  'tw-text-base',
  'tw-font-normal',
  'tw-leading-5',
  'tw-text-center',
  'tw-m-0',
  'md:tw-text-xl',
  'md:tw-text-left',
];
