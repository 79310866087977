import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import React from 'react';
import { HeroCard } from '../components/HeroCard';
import { PageSection } from '@gik/ui/gik/PageSection';
import { Layout } from '@gik/ui/gik/Layout/Layout';
import { Button } from '@gik/ui/Button';
import { isVideo } from '@gik/core/utils/fileExtensions';
import { openSearch } from '@gik/search/components/SearchStore';
import { SvgIcon } from '@gik/ui/SvgIcon';
import SearchIcon from '@heroicons/react/solid/SearchIcon';
import { Image } from '@gik/ui/Image';
import { getAssetsUrl } from '@gik/core/utils/LinkUtils';
import animation from '../assets/animations/mealtrain/animation.json';
import { isEmpty } from '@gik/core/utils/validator';

/**
 * https://www.figma.com/design/FT2ninjhNiQOXfnOTaZfqy/%F0%9F%91%A8%F0%9F%8F%BC%E2%80%8D%F0%9F%8E%A4-Jade's-workspace-of-asset-creation?node-id=4696-8681&m=dev
 */
function HomePageHeroSectionComp() {
  const bem = useBemCN('homepage-hero-section');

  const findAPageOnClick = React.useCallback(() => openSearch('HomePageFindAPage'), []);

  return (
    <div
      {...bem(null, null, ['tw-relative', 'tw-overflow-hidden'])}
      style={
        !isEmpty(heroSection.backgroundColor)
          ? {
              background: heroSection.backgroundColor,
            }
          : {}
      }
    >
      {isVideo(heroSection.backgroundVideo) && (
        <video autoPlay muted loop {...bem('background', null, ['tw-absolute'])}>
          <source src={heroSection.backgroundVideo} />
        </video>
      )}
      <PageSection
        asElement={'header'}
        {...bem('header')}
        style={
          !isEmpty(heroSection.backgroundImage)
            ? {
                backgroundImage: `url(${heroSection.backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }
            : {}
        }
      >
        <HeroCard hero={heroSection.hero}>
          <Layout direction={'column'} align={'center'} gap={8}>
            <Layout gap={4} className={'md:tw-min-w-xs'}>
              <Button type={'a'} href="/create" className={'tw-flex-1'}>
                Start a page
              </Button>

              <Button
                variant="white"
                append={<SvgIcon Icon={SearchIcon} />}
                onClick={findAPageOnClick}
                className={'tw-flex-1'}
              >
                Find a page
              </Button>
            </Layout>
            <Layout direction={'row'} align={'center md:start'} perpendicular={'center'} gap={8}>
              <p className={'tw-m-0 tw-text-neutral-500 tw-font-body tw-text-sm tw-leading-6'}>As seen on:</p>
              <Image src={`${getAssetsUrl()}landing-pages/homepage/today-logo.png`} />
              <Image src={`${getAssetsUrl()}landing-pages/homepage/wired-logo.png`} />
            </Layout>
          </Layout>
        </HeroCard>
      </PageSection>
    </div>
  );
}

const heroSection = {
  hero: {
    title: 'Organize Support for Anyone, Anywhere.',
    content:
      'Versatile, flexible support from near and far. Take turns providing meals, rides, childcare, gift cards, donations, and more. All for free.',
    animation: {
      data: animation,
      assetsPrepend: getAssetsUrl().concat('landing-pages/animations/mealtrain/images/'),
    },
  },

  backgroundColor: 'linear-gradient(99.85deg, #E2FEFF 0.01%, #FDF2F6 100.01%)',
  backgroundVideo: null,
  backgroundImage: null,
};

export const HomePageHeroSection = withComponentErrorBoundary(HomePageHeroSectionComp);
